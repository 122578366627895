import { notifications } from "@mantine/notifications";

const useRailsToast = () => {
  function responseToMessages(messages: any[]) {
    if (typeof messages === String.name) {
      return messages;
    }
    return Object.keys(messages).map((key: string) => {
      if (["info", "base"].includes(key)) {
        //@ts-ignore
        return messages[key];
      }
      const messageKey = key.replace(/_|\./g, " ");
      return `${messageKey.replace(/^\w/, (c) => c.toUpperCase())} ${
        //@ts-ignore
        messages[key].join(", ")
      }`;
    });
  }

  const railsToast = (jsonResponse: any, status?: number) => {
    const messages = responseToMessages(jsonResponse);
    const messageString = messages.join(", ");
    if (messages.length === 0) {
      notifications.show({
        title: "Error",
        message: "Oops, something went wrong, maybe try again?",
        color: "red",
        duration: 6000,
        isClosable: true,
        position: "top-right",
      });
    } else if (status === 201) {
      notifications.show({
        title: "Success",
        message: messageString,
        color: "green",
        duration: 6000,
        isClosable: true,
        position: "top-right",
      });
    } else if ([null, 200, 304].includes(status || null)) {
      notifications.show({
        message: messageString,
        duration: 6000,
        isClosable: true,
        position: "top-right",
      });
    } else if (status && status >= 500) {
      notifications.show({
        title: "Error",
        message: messageString,
        color: "yellow",
        duration: 6000,
        isClosable: true,
        position: "top-right",
      });
    } else {
      notifications.show({
        message: messageString,
        color: "red",
        duration: 6000,
        isClosable: true,
        position: "top-right",
      });
    }
  };
  return railsToast;
};

export default useRailsToast;
